import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Typography, Paper, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
  },
  paper: {
    padding: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  section: {
    marginBottom: theme.spacing(3),
  },
  subtitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  list: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }
}));

function Privacy() {
  const classes = useStyles();

  React.useEffect(() => {
    const navbar = document.querySelector('.MuiAppBar-root');
    if (navbar) {
      navbar.style.display = 'none';
    }

    return () => {
      if (navbar) {
        navbar.style.display = 'flex';
      }
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Privacy policy | Fleet Insight</title>
        <meta name="description" content="Fleet Insight Privacy Policy" />
      </Helmet>
      <Container className={classes.container}>
        <Paper className={classes.paper}>
          <Typography variant="h3" className={classes.title}>
            Privacy Policy
          </Typography>
          <Typography variant="body2" paragraph>
            Updated: January 21,2025
          </Typography>

          <div className={classes.section}>
            <Typography variant="h6" gutterBottom>
              1. Introduction
            </Typography>
            <Typography paragraph>
              Welcome to Fleet Insight. We respect your privacy and are committed to protecting your personal data. 
              This privacy policy explains how we collect, use, and protect your information when you use our website and services.
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" gutterBottom>
              2. Information We Collect
            </Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
              2.1 Information You Provide
            </Typography>
            <ul className={classes.list}>
              <li><Typography>Contact information service@fltins.com </Typography></li>
              <li><Typography>Account credentials</Typography></li>
              <li><Typography>Payment information</Typography></li>
              <li><Typography>Any other information you choose to provide</Typography></li>
            </ul>

            <Typography variant="subtitle1" className={classes.subtitle}>
              2.2 Automatically Collected Information
            </Typography>
            <ul className={classes.list}>
              <li><Typography>IP address</Typography></li>
              <li><Typography>Browser type and version</Typography></li>
              <li><Typography>Device information</Typography></li>
              <li><Typography>Usage data</Typography></li>
              <li><Typography>Cookies and similar tracking technologies</Typography></li>
            </ul>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" gutterBottom>
              3. How We Use Your Information
            </Typography>
            <Typography>We use your information to:</Typography>
            <ul className={classes.list}>
              <li><Typography>Provide and maintain our services</Typography></li>
              <li><Typography>Process your transactions</Typography></li>
              <li><Typography>Send administrative information</Typography></li>
              <li><Typography>Improve our services</Typography></li>
              <li><Typography>Communicate with you</Typography></li>
              <li><Typography>Ensure security and prevent fraud</Typography></li>
            </ul>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" gutterBottom>
              4. Information Sharing and Disclosure
            </Typography>
            <Typography>We may share your information with:</Typography>
            <ul className={classes.list}>
              <li><Typography>Service providers and business partners</Typography></li>
              <li><Typography>Legal authorities when required by law</Typography></li>
              <li><Typography>Third parties with your consent</Typography></li>
            </ul>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" gutterBottom>
              5. Data Security
            </Typography>
            <Typography paragraph>
              We implement appropriate security measures to protect your personal information.
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" gutterBottom>
              6. Your Rights
            </Typography>
            <Typography>You have the right to:</Typography>
            <ul className={classes.list}>
              <li><Typography>Access your personal data</Typography></li>
              <li><Typography>Correct inaccurate data</Typography></li>
              <li><Typography>Request deletion of your data</Typography></li>
              <li><Typography>Opt-out of marketing communications</Typography></li>
              <li><Typography>Request data portability</Typography></li>
            </ul>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" gutterBottom>
              7. Cookie Policy
            </Typography>
            <Typography>We use cookies to:</Typography>
            <ul className={classes.list}>
              <li><Typography>Improve site functionality</Typography></li>
              <li><Typography>Analyze site usage</Typography></li>
              <li><Typography>Personalize content</Typography></li>
            </ul>
            <Typography>You can control cookies through your browser settings.</Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" gutterBottom>
              8. Children's Privacy
            </Typography>
            <Typography paragraph>
              Our services are not intended for children under 13. We do not knowingly collect data from children.
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" gutterBottom>
              9. Changes to This Policy
            </Typography>
            <Typography paragraph>
              We may update this policy periodically. We will notify you of any material changes.
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" gutterBottom>
              10. Contact Us
            </Typography>
            <Typography paragraph>
              For questions about this policy, contact us at: service@fltins.com
            </Typography>
          </div>
        </Paper>
      </Container>
    </>
  );
}

export default Privacy; 