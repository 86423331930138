import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  makeStyles,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useLanguage } from '../contexts/LanguageContext';
import LanguageToggle from './LanguageToggle';
import ThemeToggle from './ThemeToggle';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    transition: 'all 0.3s ease',
    '&.scrolled': {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      backdropFilter: 'blur(8px)',
      WebkitBackdropFilter: 'blur(8px)',
    },
  },
  toolbar: {
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2),
    position: 'relative',
  },
  logo: {
    height: '40px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      right: '50%',
      transform: 'translateX(50%)',
    },
  },
  navLinks: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    '& > button': {
      margin: '0 16px',
      fontSize: '14px',
      letterSpacing: '1px',
      padding: '6px 16px',
      position: 'relative',
      color: 'rgba(255, 255, 255, 0.7)',
      transition: 'color 0.3s ease',
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        width: 0,
        height: '2px',
        backgroundColor: '#fff',
        transition: 'all 0.3s ease',
        transform: 'translateX(-50%)',
      },
      '&:hover': {
        color: '#fff',
        backgroundColor: 'transparent',
        '&::after': {
          width: '80%',
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
  },
  menuButton: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  drawerPaper: {
    width: 240,
    backgroundColor: theme.palette.background.paper,
  },
  drawerList: {
    paddingTop: theme.spacing(2),
  },
  leftSection: {
    display: 'flex',
    alignItems: 'center',
  },
  rightSection: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function Navbar({ scrollToSection }) {
  const classes = useStyles();
  const { language } = useLanguage();
  const [scrolled, setScrolled] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const texts = {
    zh: {
      home: '首页',
      services: '客户',
      contact: '联系',
    },
    en: {
      home: 'Home',
      services: 'Customers',
      contact: 'Contact',
    },
  };

  const t = texts[language];

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setScrolled(offset > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleNavClick = (section) => {
    scrollToSection(section);
    if (isMobile) {
      setDrawerOpen(false);
    }
  };

  const handleLogoClick = () => {
    scrollToSection('home');
  };

  const navigationItems = [
    { text: t.home, section: 'home' },
    { text: t.services, section: 'customers' },
    { text: t.contact, section: 'contact' },
  ];

  const drawer = (
    <Drawer
      variant="temporary"
      anchor="left"
      open={drawerOpen}
      onClose={handleDrawerToggle}
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <List className={classes.drawerList}>
        {navigationItems.map((item) => (
          <ListItem 
            button 
            key={item.section}
            onClick={() => handleNavClick(item.section)}
          >
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );

  return (
    <AppBar 
      position="fixed" 
      className={`${classes.appBar} ${scrolled ? 'scrolled' : ''}`}
    >
      <Toolbar className={classes.toolbar}>
        <div className={classes.leftSection}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <img 
            src="/images/logo.png" 
            alt="Fleet Insight Logo" 
            className={classes.logo}
            onClick={handleLogoClick}
          />
        </div>

        <div className={classes.navLinks}>
          {navigationItems.map((item) => (
            <Button
              key={item.section}
              color="inherit"
              onClick={() => handleNavClick(item.section)}
            >
              {item.text}
            </Button>
          ))}
        </div>

        <div className={classes.rightSection}>
          <div className={classes.controls}>
            <LanguageToggle />
            <ThemeToggle />
          </div>
        </div>
      </Toolbar>
      {drawer}
    </AppBar>
  );
}

export default Navbar;