import React, { useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { CssBaseline, makeStyles } from '@material-ui/core';
import { LanguageProvider } from './contexts/LanguageContext';
import { ThemeProvider, useTheme } from './contexts/ThemeContext';
import Header from './components/Header';
import Home from './components/Home';
import Customers from './components/Customers';
import Contact from './components/Contact';
import Privacy from './components/Privacy';
import Footer from './components/Footer';
import Terms from './components/Terms';
import './App.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  section: {
    padding: theme.spacing(4, 0),
  },
}));

function AppContent() {
  const { isDarkMode } = useTheme();
  const classes = useStyles();

  const theme = createTheme({
    palette: {
      type: isDarkMode ? 'dark' : 'light',
      background: {
        default: isDarkMode ? '#121212' : '#fafafa',
      },
    },
  });

  const homeRef = useRef(null);
  const customersRef = useRef(null);
  const contactRef = useRef(null);

  const scrollToSection = (sectionId) => {
    const sectionRefs = {
      home: homeRef,
      customers: customersRef,
      contact: contactRef
    };
    const sectionRef = sectionRefs[sectionId];
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div className={classes.root}>
          <Header scrollToSection={scrollToSection} />
          <Routes>
            <Route path="/" element={
              <main className={classes.main}>
                <div ref={homeRef}><Home /></div>
                <div ref={customersRef} className={classes.section}><Customers /></div>
                <div ref={contactRef} className={classes.section}><Contact /></div>
              </main>
            } />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/terms-of-use" element={<Terms />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </MuiThemeProvider>
  );
}

function App() {
  return (
    <LanguageProvider>
      <ThemeProvider>
        <AppContent />
      </ThemeProvider>
    </LanguageProvider>
  );
}

export default App;