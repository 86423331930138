import React from 'react';
import { Button } from '@material-ui/core';
import { useLanguage } from '../contexts/LanguageContext';

function LanguageToggle() {
  const { language, toggleLanguage } = useLanguage();

  return (
    <Button onClick={toggleLanguage} color="inherit">
      {language === 'zh' ? 'EN' : '中文'}
    </Button>
  );
}

export default LanguageToggle;