import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Typography, Paper, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
  },
  paper: {
    padding: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  section: {
    marginBottom: theme.spacing(3),
  },
  subtitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  list: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }
}));

function Terms() {
  const classes = useStyles();

  React.useEffect(() => {
    const navbar = document.querySelector('.MuiAppBar-root');
    if (navbar) {
      navbar.style.display = 'none';
    }

    return () => {
      if (navbar) {
        navbar.style.display = 'flex';
      }
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Terms of use | Fleet Insight</title>
        <meta name="description" content="Fleet Insight Terms of Use" />
      </Helmet>
      <Container className={classes.container}>
        <Paper className={classes.paper}>
          <Typography variant="h3" className={classes.title}>
            Terms of Use
          </Typography>
          <Typography variant="body2" paragraph>
            Updated: January 21,2025
          </Typography>

          <div className={classes.section}>
            <Typography variant="h6" gutterBottom>
              1. Agreement to Terms
            </Typography>
            <Typography paragraph>
              By accessing Fleet Insight, you agree to these Terms of Use and our Privacy Policy.
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" gutterBottom>
              2. Use License
            </Typography>
            <Typography paragraph>
              We grant you a limited, non-exclusive, non-transferable license to access and use our Site for personal use.
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" gutterBottom>
              3. Prohibited Uses
            </Typography>
            <Typography>You may not:</Typography>
            <ul className={classes.list}>
              <li><Typography>Use the Site for unlawful purposes</Typography></li>
              <li><Typography>Violate any applicable laws or regulations</Typography></li>
              <li><Typography>Interfere with Site security</Typography></li>
              <li><Typography>Attempt to gain unauthorized access</Typography></li>
              <li><Typography>Upload malicious code</Typography></li>
              <li><Typography>Collect user information without consent</Typography></li>
            </ul>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" gutterBottom>
              4. User Content
            </Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
              4.1 Content Responsibility
            </Typography>
            <Typography paragraph>
              You are responsible for any content you submit to the Site.
            </Typography>

            <Typography variant="subtitle1" className={classes.subtitle}>
              4.2 Content Rights
            </Typography>
            <Typography paragraph>
              You retain rights to your content but grant us a license to use it for Site operations.
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" gutterBottom>
              5. Intellectual Property
            </Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
              5.1 Our Rights
            </Typography>
            <Typography paragraph>
              All Site content and trademarks are our property.
            </Typography>

            <Typography variant="subtitle1" className={classes.subtitle}>
              5.2 Your Use
            </Typography>
            <Typography paragraph>
              You may not use our intellectual property without permission.
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" gutterBottom>
              6. Disclaimers
            </Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
              6.1 Service "As Is"
            </Typography>
            <Typography paragraph>
              We provide the Site "as is" without warranties.
            </Typography>

            <Typography variant="subtitle1" className={classes.subtitle}>
              6.2 Technical Issues
            </Typography>
            <Typography paragraph>
              We are not liable for any service interruptions.
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" gutterBottom>
              7. Limitation of Liability
            </Typography>
            <Typography>We are not liable for:</Typography>
            <ul className={classes.list}>
              <li><Typography>Indirect damages</Typography></li>
              <li><Typography>Lost profits</Typography></li>
              <li><Typography>Data loss</Typography></li>
              <li><Typography>Service interruptions</Typography></li>
            </ul>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" gutterBottom>
              8. User Accounts
            </Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
              8.1 Account Security
            </Typography>
            <Typography paragraph>
              You are responsible for maintaining account security.
            </Typography>

            <Typography variant="subtitle1" className={classes.subtitle}>
              8.2 Account Termination
            </Typography>
            <Typography paragraph>
              We may terminate accounts for violations.
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" gutterBottom>
              9. Changes to Terms
            </Typography>
            <Typography paragraph>
              We may modify these terms at any time. Continued use constitutes acceptance.
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" gutterBottom>
              10. Governing Law
            </Typography>
            <Typography paragraph>
              These terms are governed by applicable law.
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" gutterBottom>
              11. Contact
            </Typography>
            <Typography paragraph>
              Questions about these terms? Contact us at: service@fltins.com
            </Typography>
          </div>
        </Paper>
      </Container>
    </>
  );
}

export default Terms; 