/* eslint-disable no-unused-vars */
import React from 'react';
import { Typography, Container, Grid, makeStyles } from '@material-ui/core';
import { Link as MuiLink } from '@material-ui/core';
import { useTheme } from '../contexts/ThemeContext';

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor: theme.palette.type === 'dark' ? '#202020' : '#f1f1f1', // 根据主题设置背景颜色
    color: theme.palette.type === 'dark' ? '#fff' : '#000', // 根据主题设置文本颜色
  },
}));

function Footer() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <footer className={classes.footer}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">
              © {new Date().getFullYear()} Fleet Insight Limited. All rights reserved.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" align="right">
              <MuiLink 
                href="/privacy-policy" 
                color="inherit"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy policy
              </MuiLink>
              {' | '}
              <MuiLink 
                href="/terms-of-use" 
                color="inherit"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of service
              </MuiLink>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}

export default Footer;