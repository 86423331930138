import React from 'react';
import Navbar from './Navbar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'fixed', // 改为 fixed 定位
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 10, // 确保header在视频上方
    backgroundColor: 'transparent', // 或者您想要的任何背景色
  },
  // ... 其他样式 ...
}));

function Header({ scrollToSection }) {
  const classes = useStyles();

  return (
    <header className={classes.header}>
      <Navbar scrollToSection={scrollToSection} />
    </header>
  );
}

export default Header;